<template>
  <div>
    <div class="title">企业公告</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- dropdown -->
        <div class="filter_search_dropdown">
          <el-image
            :src="require('@/assets/icon/page.png')"
            fit="contain"
            style="width: 15px; height: 15px"
          >
          </el-image>
          <div class="label">发送状态：</div>
          <el-dropdown @command="handleFilter">
            <span class="el-dropdown-link">
              {{ search.state_filter.label }}
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in stateOptions"
                :key="item.label"
                :command="item"
                @click.native="searchAction(item.value)"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
        <div
          class="custom_button no_select"
          @click="$router.push({ name: 'NoticeEditor' })"
        >
          添加
        </div>
      </div>
    </div>

    <!-- table -->
    <el-table
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="state" label="状态">
        <template v-slot:default="scope">
          {{ stateOptions[scope.row.state].label }}
        </template>
      </el-table-column>
      <el-table-column prop="range" label="发布范围"> </el-table-column>
      <el-table-column prop="create_time" label="创建/更新时间">
      </el-table-column>
      <el-table-column prop="create_time" label="发布时间"> </el-table-column>
      <el-table-column label="操作">
        <template v-slot:default="scope">
          <div class="operations">
            <div @click="check(scope.row)">查看</div>
            <div @click="editNotice(scope.row)">修改</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <el-dialog :title="dialog.title" :visible.sync="dialog.visible">
      <span v-html="dialog.content"></span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialog.visible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      search: {
        keyword: "",
        state_filter: {
          label: "全部",
          value: null,
        },
      },
      loading: true,
      tableData: [],
      stateOptions: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "显示",
          value: 1,
        },
        {
          label: "隐藏",
          value: 2,
        },
      ],
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      dialog: {
        visible: false,
        title: "",
        content: "",
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
    this.loadDatas = this.loadDataFactorys();
    this.loadData();
  },
  methods: {
    ...mapActions("enterprise", [
      "getEnterpriseNoticeList",
      "getEnterpriseNoticeLists",
    ]),

    editNotice(e) {
      console.log("修改公告", e);
      this.$router.push({
        name: "NoticeEditor",
        params: e,
      });
    },
    handleFilter(e) {
      this.search.state_filter = e;
    },

    check(e) {
      this.dialog.title = e.title;
      this.dialog.content = e.content;
      this.dialog.visible = true;
    },

    /**
     * 函数工厂
     */
    loadDataFactory(keyword = null, state = null) {
      let params = {};
      if (keyword) params.keywords = keyword;
      if (state) params.state = state;
      return () => {
        params.page = this.page.page;
        this.loading = true;
        console.log("parmas", params);
        this.getEnterpriseNoticeList(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.loading = false;
          })
          .catch((e) => {
            this.$message.error(e);
            this.loading = false;
          });
      };
    },

    loadDataFactorys(keyword = null, state = null) {
      let params = {};
      if (keyword) params.keywords = keyword;
      if (state) params.state = state;
      return () => {
        params.page = this.page.page;
        this.loading = true;
        console.log("parmas", params);
        this.getEnterpriseNoticeLists(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.loading = false;
          })
          .catch((e) => {
            this.$message.error(e);
            this.loading = false;
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(
        this.search.keyword,
        this.search.state
      );
      this.loadData();
    },

    /**
     * 获取状态
     */
    searchAction(value) {
      let state = value;
      this.loadData = this.loadDataFactorys(this.stateOptions.label, state);
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_dropdown {
      display: flex;
      align-items: center;
      color: #495060;
      font-size: 14px;
      margin-right: 15px;

      .label {
        margin-left: 8px;
      }
    }

    .filter_search_input {
      width: 260px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
